<template>
  <div>
    <b-row class="mb-1">
      <b-col lg="2" md="2">
        <label> Status Receita</label>
        <v-select
          id="tipo_receita"
          v-model="tipo_receita"
          :options="tipoReceita"
          :reduce="(receita) => receita.value"
          label="text"
          class="select-size-sm"
          @input="alterouTipoReceita"
        >
          <div slot="no-options">
            não encontrado!
          </div>
        </v-select>
      </b-col>

      <!-- FORNECEDORES SELECT -->
      <!-- <b-col>
        <label>Fornecedor</label>
        <v-select
          id="cliente"
          v-model="fornecedor_id"
          :clearable="true"
          :options="getListPessoa"
          label="nome"
          class="select-size-sm"
          @input="alterouFornecedor"
        >
          <div slot="no-options">
            Fornecedor não encontrado!
          </div>
        </v-select>
      </b-col> -->

      <b-col lg="5" md="7">
        <b-form-group
          label="Período"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="tipo"
              v-model="periodo"
              :options="periodos"
              text-field="text"
              value-field="value"
              style="height: 32px"
              @change="alterouPeriodo"

            >
            </b-form-select>
            <b-form-datepicker
              id="data_inicio"
              size="sm"
              v-model="data_inicio"
              :date-format-options="{
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
          }"
              close-button
              weekday-header-format="narrow"
              offset="-25"
              dropdown
              hide-header
              label-close-button="Fechar"
              label-reset-button="Apagar Data"
              labelHelp=""
              locale="pt-BR"
              placeholder="Data"
              reset-button
              @input="atualizarLista"
            />
            <b-form-datepicker
              id="data_final"
              size="sm"
              v-model="data_final"
              :date-format-options="{
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
          }"
              close-button
              hide-header
              weekday-header-format="narrow"
              offset="-25"
              dropdown
              label-close-button="Fechar"
              label-reset-button="Apagar Data"
              labelHelp=""
              locale="pt-BR"
              placeholder="Data"
              reset-button
              @input="atualizarLista"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col>
        <label
        ><feather-icon icon="SearchIcon"></feather-icon>
          Procurar</label
        >
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filtro" @keyup="$store.commit('receitas/UPDATE_FILTRO_PESQUISAR', filtro)" placeholder="Procurar" />
          <b-input-group-append>
            <b-button @click="$store.commit('receitas/UPDATE_FILTRO_PESQUISAR', ''), filtro = ''"> Limpar </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import {
  BIcon,
  BButton,
  BListGroup,
  BListGroupItem,
  VBToggle,
  BFormDatepicker,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BFormGroup,
  BFormSelect,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapState } from "vuex";
import moment from "moment";

export default {
  directives: { Ripple, "b-toggle": VBToggle },
  components: {
    BIcon,
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
    vSelect,
    VBToggle,
    BFormDatepicker,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormGroup,
    BFormSelect,
  },
  props: ['fornecedorSelecionado'],
  data() {
    return {
      filtro: '',
      periodo: 1,
      data_inicio: this.$getDate.hoje(),
      data_final: this.$getDate.hoje(),
      fornecedor_id: 0,
      tipo_receita: 1,
      nome: "",
      fornSelIndex: "",
      tipoFornecedorSelecionado: "",
      clienteSelecionado: "",
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
      },

      periodos: [
        { value: 1, text: "Hoje" },
        { value: 2, text: "Esta Semana" },
        { value: 3, text: "Este mês" },
        { value: 4, text: "Próximos 30 dias" },
        { value: 0, text: "Mostrar Todos" },
        { value: 5, text: "Outro Período" },
      ],

      tipoReceita: [
        { value: 1, text: "A Receber" },
        { value: 2, text: "Recebidas" },
        { value: 0, text: "Todas" },
      ],
    };
  },

  mounted() {
    //  this.$emit("close-left-sidebar", true);
  },

  computed: {
    ...mapState({
      fornecedores: (state) => state.fornecedores.fornecedores,
      receitas: (state) => state.receitas.receitas,
      selecionarReceber: (state) => state.receitas.selecionarReceber,
      selecionarRecebidas: (state) => state.receitas.selecionarRecebidas,
    }),

    getListPessoa() {
      let result = [];
      let nome = "";

      nome = "Fornecedor";
        this.fornecedores.forEach((r) =>
          result.push({ id: r.id, nome: r.fornecedor_nome })
        );
      this.tipoFornecedorSelecionado = nome;
      return result;
    },
  },

  methods: {
    filtrarVencidas() {
      this.periodo = 5;
      this.data_inicio = this.receitas.data_divida;
      this.data_final = this.$getDate.subDias(null, 1);
      this.alterouTipoReceita(1);
    },

    alterouTipoReceita(tipoReceita) {

      this.tipo_receita = tipoReceita;

      this.$store.commit(
        "receitas/SELECIONAR_RECEBER",
        this.tipo_receita == 0 || this.tipo_receita == 1
      );
      this.$store.commit(
        "receitas/SELECIONAR_RECEBIDAS",
        this.tipo_receita == 0 || this.tipo_receita == 2
      );

      const payload = {
        periodo: this.periodo,
        data_inicio: this.data_inicio,
        data_final: this.data_final,
        tipo_receita: this.tipo_receita,
      };


      this.$store.commit("receitas/SET_DATAS", payload); // this.listarReceitas(payload);
    },

    listarReceitas(payload) {
      this.$store.dispatch("receitas/listaReceitas", payload);
    },

    atualizarLista() {
      if (this.data_inicio.length > 0 && this.data_final.length > 0) {
        const payload = {
          periodo: this.periodo,
          data_inicio: this.data_inicio,
          data_final: this.data_final,
          tipo_receita: this.tipo_receita,
        };
        this.listarReceitas(payload);
      }
    },

    alterouPeriodo() {
      if (this.periodo === 0) {
        this.data_inicio = "";
        this.data_final = "";
      } else if (this.periodo === 1) {
        this.data_inicio = this.$getDate.hoje();
        this.data_final = this.$getDate.hoje();
      } else if (this.periodo === 2) {
        this.data_inicio = moment().day(0).format("YYYY-MM-DD"); // domingo desta semana
        this.data_final = moment().day(6).format("YYYY-MM-DD"); // sábado desta semana
      } else if (this.periodo === 3) {
        this.data_inicio = moment().startOf("month").format("YYYY-MM-DD");
        this.data_final = moment().endOf("month").format("YYYY-MM-DD");
      } else if (this.periodo === 4) {
        this.data_inicio = this.$getDate.hoje();
        this.data_final = this.$getDate.addDias(null, 30);
      } else if (this.periodo === 5) {
        return;
      }

      let payload = {
        periodo: this.periodo,
        data_inicio: this.data_inicio,
        data_final: this.data_final,
        tipo_receita: this.tipo_receita,
      };

      this.listarReceitas(payload);
    },

    alterouFornecedor(fornecedor_id) {
      this.$emit("alterouFornecedor", fornecedor_id);
    },

    selecionaCliente(selecionado, index) {
      this.clienteSelecionado = selecionado;
      this.fornSelIndex = index;
      this.$emit("alterouCliente", selecionado);
    },
  },
  filters: {
    formataData(data) {
      if (!data) return;

      let d = data.split("-");
      return `${d[2]}/${d[1]}/${d[0]}`;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
.sidebar-left {
  width: 290px;
}
.todo-application .content-area-wrapper .sidebar .todo-sidebar {
  width: 282px !important;
}
</style>
