
export default {

  formas: [
    { value: "", text: "Forma Recebimento" },
    { value: "boleto", text: "Boleto Bancário" },
    { value: "credito", text: "Cartão de Crédito" },
    { value: "debito", text: "Cartão de Débito" },
    { value: "cheque", text: "Cheque" },
    { value: "deposito", text: "Depósito Bancário" },
    { value: "dinheiro", text: "Dinheiro" },
    { value: "pix", text: "Pix" },
    { value: "transferencia", text: "Transferência Bancária" },
    { value: "outros", text: "Outros" },

  ],

  formasAgrupadas:
    {
      'null': 'Não Informado',
      boleto: "Boleto Bancário",
      credito: "Cartão de Crédito",
      debito: "Cartão de Débito",
      cheque: "Cheque",
      deposito: "Depósito Bancário",
      dinheiro: "Dinheiro",
      pix: "Pix",
      transferencia: "Transferência Bancária",
      deducao: "Abatimento de Crédito",
      diversos: "DIVERSOS",
      outros: "Outros",
    },

  formasEtiquetas :
    {
      'null': 'light-secondary',
      dinheiro: 'light-primary',
      outros: 'light-secondary',
      credito: 'light-success',
      debito: 'light-success',
      cheque: 'light-danger',
      boleto: 'light-warning',
      deposito: 'light-info',
      transferencia: 'light-info',
      deducao: 'light-danger',
      pix: 'light-info',
      diversos: 'light-primary',
    }


}
