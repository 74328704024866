<template>
  <b-modal
    id="lancarReceita"
    no-close-on-backdrop
    no-close-on-esc
    centered
    hide-footer
    size="xl"
    title="Lançar Receita"
  >
    <b-card>
      <b-alert :variant="getErrors.color" v-if="getErrors.hasMessage" show>
        <div
          class="alert-body"
          v-for="(error, index) in getErrors.messageArray"
          :key="index"
        >
          {{ error[0] }}
        </div>

        <div class="alert-body">{{ getErrors.message }}</div>
      </b-alert>
      <validation-observer ref="receitaCadastroValidation">
        <b-row>
          <!--  DATA LANÇAMENTO -->
          <b-col lg="4" md="6">
            <validation-provider
              #default="{ errors }"
              name="Data de Lançamento"
              rules="required"
            >
              <b-form-group label="Data de Lançamento" label-for="compra">
                <b-form-datepicker
                  id="compra"
                  v-model="form.data_lancamento"
                  :date-format-options="{
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                  }"
                  close-button
                  hide-header
                  label-close-button="Fechar"
                  label-reset-button="Apagar Data"
                  label-help=""
                  locale="pt-BR"
                  placeholder="Data"
                  reset-button
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!--  FORNECEDOR -->
          <b-col>
            <validation-provider
              #default="{ errors }"
              name="Cliente"
              rules="required"
            >
              <b-form-group label="Cliente" label-for="cliente">
                <v-select
                  style="background-color: white"
                  id="cliente"
                  v-model="form.empresa_id"
                  :reduce="(empresas) => empresas.id"
                  label="nome"
                  :options="empresas"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="3">
            <validation-provider
              #default="{ errors }"
              name="Forma Recebimento"
              rules="required"
            >
              <b-form-group
                label="Forma Recebimento"
                label-for="formaPagamento"
              >
                <b-form-select
                  v-model="form.forma_recebimento"
                  :options="formas"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <!-- CATEGORIAS-->
          <b-col>
            <b-form-group label="Categorias" label-for="planos">
              <validation-provider
                #default="{ errors }"
                name="Categoria"
                rules="required"
              >
                <v-select
                  v-model="form.plano_id"
                  :options="receitasAgrupadas"
                  :reduce="(plano) => plano.id"
                  label="nome"
                  :selectable="(option) => option.posicao > 0"
                >
                  <template #list-header>
                    <li
                      v-b-toggle.sidebar-invoice-add-new-customer
                      class="add-new-client-header d-flex align-items-center my-50 ml-1 text-success"
                      @click="cadastrarPlano"
                    >
                      <feather-icon
                        class="text-success"
                        icon="PlusIcon"
                        size="16"
                      />
                      <span class="align-middle ml-25"
                        >Cadastrar Categoria</span
                      >
                    </li>
                  </template>

                  <template #option="{ nome, posicao }">
                    <h5
                      v-if="posicao == 0"
                      class="text-primary"
                      style="margin: 0"
                    >
                      {{ nome }}
                    </h5>
                    <em v-else-if="posicao == 1" class="pl-2">{{ nome }}</em>
                    <em v-else class="pl-4"> - {{ nome }}</em>
                  </template>

                  <div slot="no-options">Categoria não encontrada!</div>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <validation-provider
              #default="{ errors }"
              name="Descrição"
              rules="required"
            >
              <b-form-group label="Descrição" label-for="descricao">
                <b-form-input id="descricao" v-model="form.descricao" />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="3">
            <validation-provider
              #default="{ errors }"
              name="Valor"
              rules="required|between:0,999999"
            >
              <b-form-group label="Valor" label-for="valor_parcela">
                <b-input-group prepend="R$">
                  <money
                    id="valor"
                    v-model="form.valor"
                    v-bind="money"
                    class="form-control"
                  />
                </b-input-group>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>
      <b-row class="mt-2">
        <b-col>
          <b-button
            :disabled="submit"
            block
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            @click="cadastrar"
            variant="success"
          >
            <template>
              <b-spinner v-if="spinner" small />
              <span v-if="spinner">Aguarde...</span>
              <span v-else>Cadastrar</span>
            </template>
          </b-button>
        </b-col>
        <b-col>
          <b-button
            block
            variant="outline-secondary"
            @click="$bvModal.hide('lancarReceita')"
          >
            Fechar
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <modal-cadastrar-plano ref="CadastrarPlanoComponent"/>
  </b-modal>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BModal,
  BFormGroup,
  BFormSelect,
  BFormDatepicker,
  BFormInput,
  BInputGroup,
  BSpinner,
  BAlert,
  VBToggle,
} from "bootstrap-vue";
import vSelect from "vue-select";
import money from "v-money";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { min, max, required } from "@validations";
import { mapState, mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formaRecebimento from "../formaRecebimento";
import ModalCadastrarPlano from "../ModalCadastrarPlano.vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BModal,
    BFormGroup,
    BFormSelect,
    BFormDatepicker,
    BFormInput,
    BInputGroup,
    BSpinner,
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BAlert,
    VBToggle,
    ToastificationContent,
    ModalCadastrarPlano,
  },
  directives: { "b-toggle": VBToggle },

  data() {
    return {
      errors: false,
      required,
      min,
      max,
      spinner: false,
      submit: false,
      form: {
        empresa_id: "",
        data_lancamento: this.$getDate.hoje(),
        descricao: "",
        forma_recebimento: "",
        valor: "",
        plano_id: "",
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      getErrors: {
        hasMessage: false,
        color: "danger",
        message: "",
        messageArray: "",
      },
      ...formaRecebimento,
    };
  },

  mounted() {
    this.$store.dispatch("planos/listaPlanos");
  },

  computed: {
    ...mapGetters("planos", ["receitasAgrupadas"]),
    ...mapState({
      empresas: (state) => state.empresas.empresas,
    }),
  },

  methods: {
    cadastrar() {
      this.$refs.receitaCadastroValidation.validate().then((success) => {
        if (success) {
          this.spinner = true;
          this.submit = true;
          this.errors = false;

          this.$http
            .post("adm/receitas", this.form)
            .then((res) => {
              if (res.data) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Receita Cadastrada com Sucesso!",
                    icon: "InfoIcon",
                    variant: "success",
                  },
                });

                this.$store.dispatch("receitas/listaReceitas");
              }
              this.getErrors = false;
              this.$refs.receitaCadastroValidation.reset();
              this.resetaCampos();
            })
            .catch((error) => {
              let erro = this.$responseMessages.getMessage(error, null);
              this.getErrors = erro;
            })
            .finally(() => {
              this.spinner = false;
              this.submit = false;
            });
        }
      });
    },

    cadastrarPlano() {
      this.$refs.CadastrarPlanoComponent.SetPlanoReceitas(null);
      this.$bvModal.show('cadastrarPlano')
    },

    resetaCampos() {
      this.form = {
        empresa_id: "",
        data_lancamento: this.$getDate.hoje(),
        descricao: "",
        valor: "",
      };
    },
  },
};
</script>

<style>
</style>