<template>
  <b-modal
    id="receberReceita"
    no-close-on-backdrop
    no-close-on-esc
    centered
    hide-footer
    size="xl"
    title="Receber Receita"
  >
    <b-card>
      <b-alert :variant="getErrors.color" v-if="getErrors.hasMessage" show>
        <div
          class="alert-body"
          v-for="(error, index) in getErrors.messageArray"
          :key="index"
        >
          {{ error[0] }}
        </div>

        <div class="alert-body">{{ getErrors.message }}</div>
      </b-alert>
      <validation-observer ref="receitaCadastroValidation">
        <b-row>
          <!--  DATA VENCIMENTO -->
          <b-col>
            <validation-provider
              #default="{ errors }"
              name="Data de Recebimento"
              rules="required"
            >
              <b-form-group label="Data de Recebimento" label-for="pagamento">
                <b-form-datepicker
                  id="pagamento"
                  v-model="data_recebimento"
                  :date-format-options="{
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                  }"
                  close-button
                  hide-header
                  label-close-button="Fechar"
                  label-reset-button="Apagar Data"
                  label-help=""
                  locale="pt-BR"
                  placeholder="Data"
                  reset-button
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col>
            <validation-provider
              #default="{ errors }"
              name="Forma Recebimento"
              rules="required"
            >
              <b-form-group
                label="Forma Recebimento"
                label-for="formaPagamento"
              >
                <b-form-select
                  v-model="form.forma_recebimento"
                  :options="formas"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <!-- DESCRIÇÃO -->
          <b-col>
            <validation-provider
              #default="{ errors }"
              name="Descrição"
              rules="required"
            >
              <b-form-group label="Descrição" label-for="descricao">
                <b-form-input
                  id="descricao"
                  v-model="form.descricao"
                  disabled
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <!-- VALOR -->
          <b-col>
            <validation-provider
              #default="{ errors }"
              name="Valor da Receita"
              rules="required|between:0,999999"
            >
              <b-form-group label="Valor da Receita" label-for="valor">
                <b-input-group prepend="R$">
                  <money
                    id="valor"
                    v-model="form.valor"
                    v-bind="money"
                    class="form-control"
                    disabled
                  />
                </b-input-group>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <!-- JUROS -->
          <b-col>
            <validation-provider
              #default="{ errors }"
              name="Juros"
              rules="required|between:0,999999"
            >
              <b-form-group label="Juros" label-for="juros">
                <b-input-group prepend="R$">
                  <money
                    id="juros"
                    v-model="juros"
                    v-bind="money"
                    class="form-control"
                    @input="calculaTotal()"
                  />
                </b-input-group>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!-- TOTAL -->
          <b-col>
            <validation-provider
              #default="{ errors }"
              name="Valor Recebido"
              rules="required|between:0,999999"
            >
              <b-form-group label="Valor Recebido" label-for="valor_recebido">
                <b-input-group prepend="R$">
                  <money
                    id="valor_recebido"
                    v-model="valor_recebido"
                    :value="form.valor"
                    v-bind="money"
                    @input="calculaTotal()"
                    class="form-control"
                  />
                </b-input-group>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-provider name="Observação" rules="max:100">
              <label for="textarea-default">Observação</label>
              <b-form-input id="textarea-default" v-model="observacao" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col>
            <validation-provider
              #default="{ errors }"
              name="Conta"
              rules="required"
            >
              <b-form-group label="Conta" label-for="conta">
                <v-select
                  style="background-color: white"
                  id="conta"
                  v-model="conta_id"
                  :reduce="(contas) => contas.id"
                  label="nome"
                  :options="contas"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>
      <b-row class="mt-2">
        <b-col>
          <b-button
            :disabled="submit"
            block
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            @click="pagar"
            variant="success"
          >
            <template>
              <b-spinner v-if="spinner" small />
              <span v-if="spinner">Aguarde...</span>
              <span v-else>Receber Receita</span>
            </template>
          </b-button>
        </b-col>
        <b-col>
          <b-button
            block
            variant="outline-secondary"
            @click="$bvModal.hide('receberReceita')"
          >
            Fechar
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-modal>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BModal,
  BFormGroup,
  BFormDatepicker,
  BFormInput,
  BInputGroup,
  BFormSelect,
  BFormTextarea,
  BFormSpinbutton,
  BInputGroupPrepend,
  BSpinner,
  BAlert,
  VBToggle,
} from "bootstrap-vue";
import vSelect from "vue-select";
import money from "v-money";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { min, max, required } from "@validations";
import { mapState, mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formaRecebimento from "../formaRecebimento.js";
export default {
  directives: { "b-toggle": VBToggle },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BModal,
    BFormGroup,
    BInputGroupPrepend,
    BFormDatepicker,
    BFormInput,
    BInputGroup,
    BFormTextarea,
    BFormSpinbutton,
    BSpinner,
    vSelect,
    BFormSelect,
    ValidationObserver,
    ValidationProvider,
    BAlert,
    ToastificationContent,
    VBToggle,
  },

  props: ["form"],
  data() {
    return {
      errors: false,
      required,
      min,
      max,
      spinner: false,
      submit: false,
      data_recebimento: this.$getDate.hoje(),
      valor_recebido: "",
      juros: "",
      forma_recebimento: "",
      observacao: "",
      conta_id: "",
      ...formaRecebimento,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      getErrors: {
        hasMessage: false,
        color: "danger",
        message: "",
        messageArray: "",
      },
    };
  },

  mounted() {
    this.$store.dispatch("contas/listaContas");
  },

  computed: {
    ...mapState({
      contas: (state) => state.contas.contas,
    }),
  },

  methods: {
    calculaTotal() {
      this.valor_recebido = this.form.valor + this.juros;
    },
    pagar() {
      this.$refs.receitaCadastroValidation.validate().then((success) => {
        if (success) {
          this.spinner = true;
          this.submit = true;
          this.errors = false;

          let payload = {
            ...this.form,
            data_recebimento: this.data_recebimento,
            juros: this.juros,
            observacao: this.observacao,
            conta_id: this.conta_id,
            valor_recebido: this.valor_recebido,
          };
          this.$http
            .put(
              `adm/receitas/receber/${this.form.id}`,
              payload
            )
            .then((res) => {
              if (res.data) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Receita Recebida com Sucesso!",
                    icon: "InfoIcon",
                    variant: "success",
                  },
                });
              }
              this.getErrors = false;
              this.$refs.receitaCadastroValidation.reset();
              this.$store.dispatch("receitas/listaReceitas");
              this.form.empresa_id = 0;
              this.$bvModal.hide("receberReceita");
            })
            .catch((error) => {
              let erro = this.$responseMessages.getMessage(error, null);
              this.getErrors = erro;
            })
            .finally(() => {
              this.spinner = false;
              this.submit = false;
            });
        }
      });
    },
  },
};
</script>

<style>
</style>