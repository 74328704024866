<template>
  <div>
    <b-row>
      <b-col md="2">
        <label> Status Receita</label>
        <v-select
          id="tipo_receita"
          v-model="tipo_receita"
          :options="tipoReceita"
          :reduce="(receita) => receita.value"
          label="text"
          class="select-size-sm"
          @input="alterouTipoReceita"
        >
          <div slot="no-options">não encontrado!</div>
        </v-select>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Período"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="tipo"
              v-model="periodo"
              :options="periodos"
              text-field="text"
              value-field="value"
              style="height: 32px"
              @change="alterouPeriodo"
            >
            </b-form-select>
            <b-form-datepicker
              id="data_inicio"
              size="sm"
              v-model="data_inicio"
              :date-format-options="{
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              }"
              close-button
              weekday-header-format="narrow"
              no-flip
              dropdown
              hide-header
              label-close-button="Fechar"
              label-reset-button="Apagar Data"
              labelHelp=""
              locale="pt-BR"
              placeholder="Data"
              reset-button
              @input="alterouData"
            />
            <b-form-datepicker
              id="data_final"
              size="sm"
              v-model="data_final"
              :date-format-options="{
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              }"
              no-flip
              close-button
              hide-header
              weekday-header-format="narrow"
              dropdown
              label-close-button="Fechar"
              label-reset-button="Apagar Data"
              labelHelp=""
              locale="pt-BR"
              placeholder="Data"
              reset-button
              @input="alterouData"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Cliente" label-for="cliente" label-size="sm">
          <v-select
            style="background-color: white"
            id="cliente"
            v-model="form.empresa_id"
            :reduce="(empresas) => empresas.id"
            label="nome"
            class="select-size-sm"
            :options="empresas"
            @input="alterouCliente"
          />
          <div slot="no-options">não encontrado!</div>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="receitasFiltradas"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          :busy="busy"
          show-empty
        >
          <template #empty>
            <h4 class="text-center text-primary">
              nenhum resultado encontrado
            </h4>
          </template>

          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Aguarde...</strong>
            </div>
          </template>
          <template #cell(data_lancamento)="data">
            <span v-if="data.item.data_recebimento">{{
              data.item.data_recebimento | formataData
            }}</span>
            <span v-else>{{ data.item.data_lancamento | formataData }}</span>
          </template>

          <template #cell(valor)="data">
            <span v-if="data.item.valor_recebido">{{
              data.item.valor_recebido | formataMoeda
            }}</span>
            <span v-else>{{ data.item.valor | formataMoeda }}</span>
          </template>

          <template #row-details="row">
            <b-card class="mb-0">
              <div class="d-flex mb-2">
                <feather-icon
                  class="text-success"
                  icon="TrendingUpIcon"
                  size="19"
                />
                <h5 class="mb-0 ml-50 text-success">
                  Detalhes Receita
                </h5>
              </div>
              <b-row>
                <b-col md="4">
                  <b>Data Recebimento: </b>
                  {{ row.item.data_recebimento | formataData }}
                </b-col>           
                <b-col md="4">
                  <b>Valor Receita: </b> R$ {{ row.item.valor | formataMoeda }}
                </b-col>
                <b-col>
                  <b>Juros: </b> R$ {{ row.item.juros | formataMoeda }}
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col md="4">
                  <b>Valor Recebido: </b> R$
                  {{ row.item.valor_recebido | formataMoeda }}
                </b-col>
                <b-col> <b>Observação: </b> {{ row.item.observacao }} </b-col>
              </b-row>
            </b-card>
          </template>

          <template #cell(opcoes)="data">
            <b-button
              class="btn-icon"
              variant="flat-primary"
              style="padding: 2px"
              @click="receber(data.item)"
              v-if="!data.item.data_recebimento"
            >
              <b-spinner v-if="spinner == data.item.id" small />
              <span v-if="spinner == data.item.id">Aguarde...</span>
              <b-badge v-else variant="primary" class="px-50">Receber</b-badge>
            </b-button>
            <b-badge
              class="px-50"
              :id="`pagto-${data.index}`"
              variant="light-success"
              v-else
            >
              Recebido
            </b-badge>

            <b-button
              class="btn-icon"
              variant="flat-secondary"
              @click="data.toggleDetails"
              v-model="data.detailsShowing"
              style="padding: 2px"
              v-if="data.item.data_recebimento"
            >
              <feather-icon
                :class="data.detailsShowing ? 'text-primary' : 'text-default'"
                icon="EyeIcon"
                size="16"
              />
            </b-button>

            <b-button
              :disabled="data.item.payment_id ? true : false"
              class="btn-icon"
              variant="flat-secondary"
              @click="editar(data.item)"
              style="padding: 2px"
              v-else
            >
              <feather-icon icon="Edit2Icon" size="16" />
            </b-button>

            <b-button
              :disabled="data.item.payment_id ? true : false"
              class="btn-icon text-danger"
              variant="flat-secondary"
              @click="excluir(data.item.id, data.index)"
              style="padding: 2px"
            >
              <feather-icon icon="Trash2Icon" size="16" />
            </b-button>
          </template>
        </b-table>
      </b-col>

      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
    <modal-editar-receita :form="dados" />
    <modal-receber-receita :form="dados" />
  </div>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BSpinner,
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BFormDatepicker,
  BButton,
  BModal,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapState, mapGetters } from "vuex";
import ModalEditarReceita from "./ModalEditarReceita.vue";
import moment from "moment";
import ModalReceberReceita from "./ModalReceberReceita.vue";

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BCard,
    BSpinner,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormDatepicker,
    BButton,
    vSelect,
    ModalEditarReceita,
    BModal,
    ModalReceberReceita,
  },
  directives: {
    "b-modal": VBModal,
  },

  props: ["receitas", "filter"],
  data() {
    return {
      spinner: false,
      form: {},
      dados: {},
      tipo_receita: 0,
      periodo: 3,
      data_inicio: this.$getDate.primeiroDiaMes(),
      data_final: this.$getDate.ultimoDiaMes(),
      perPage: 50,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filterOn: [],
      fields: [
        { key: "data_lancamento", label: "Data", sortable: true },
        { key: "cliente", label: "Cliente", sortable: true },
        { key: "descricao", label: "Descrição", sortable: true },
        { key: "forma_recebimento", label: "Forma Rec.", sortable: true },
        { key: "valor", label: "Valor", sortable: true },
        { key: "opcoes", label: "Opções", thStyle: { width: "18%" } },
      ],
      tipoReceita: [
        { value: 0, text: "Todas" },
        { value: 1, text: "A Receber" },
        { value: 2, text: "Recebidas" },
      ],
      periodos: [
        { value: 0, text: "Mostrar Todos" },
        { value: 1, text: "Hoje" },
        { value: 2, text: "Esta Semana" },
        { value: 3, text: "Este mês" },
        { value: 4, text: "Próximos 30 dias" },
        { value: 5, text: "Outro Período" },
      ],
    };
  },

  mounted() {
    // Set the initial number of items
    this.totalRows = this.receitas.length;
  },

  computed: {
    ...mapState({
      empresas: (state) => state.empresas.empresas,
      busy: (state) => state.receitas.busy,
    }),
    ...mapGetters({
      receitasGetters: "receitas/receitasGetters",
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    receitasFiltradas() {
      let result = this.receitas;

      if (this.tipo_receita === 1) {
        return (result = this.receitas.filter(
          (res) => res.data_recebimento === null
        ));
      } else if (this.tipo_receita === 2) {
        return (result = this.receitas.filter(
          (res) => res.data_recebimento !== null
        ));
      } else if (this.form.empresa_id > 0) {
        return (result = this.receitas.filter(
          (res) => res.empresa_id === this.form.empresa_id
        ));
      }

      return result;
    },
  },

  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    alterouTipoReceita() {
      this.$store.commit(
        "receitas/STORE_RECEITAS_FILTRADAS",
        this.tipo_receita
      );
    },

    alterouPeriodo() {
      if (this.periodo === 0) {
        this.data_inicio = "";
        this.data_final = "";
      } else if (this.periodo === 1) {
        this.data_inicio = this.$getDate.hoje();
        this.data_final = this.$getDate.hoje();
      } else if (this.periodo === 2) {
        this.data_inicio = moment().day(0).format("YYYY-MM-DD"); // domingo desta semana
        this.data_final = moment().day(6).format("YYYY-MM-DD"); // sábado desta semana
      } else if (this.periodo === 3) {
        this.data_inicio = moment().startOf("month").format("YYYY-MM-DD");
        this.data_final = moment().endOf("month").format("YYYY-MM-DD");
      } else if (this.periodo === 4) {
        this.data_inicio = this.$getDate.hoje();
        this.data_final = this.$getDate.addDias(null, 30);
      } else if (this.periodo === 5) {
        return;
      }
      let payload = {
        periodo: this.periodo,
        data_inicio: this.data_inicio,
        data_final: this.data_final,
        tipo_receita: this.tipo_receita,
      };
      this.$store.commit("receitas/STORE_RECEITAS_FILTRADAS_DATAS", payload);
      this.alterouTipoReceita();
      this.$store.dispatch("receitas/listaReceitas");
    },

    alterouData() {
      let payload = {
        data_inicio: this.data_inicio,
        data_final: this.data_final,
      };
      this.$store.commit("receitas/STORE_RECEITAS_FILTRADAS_DATAS", payload);
      this.$store.dispatch("receitas/listaReceitas");
    },

    alterouCliente() {
      if (this.form.empresa_id === null) {
        this.form.empresa_id = 0
      }
      this.$store.commit(
        "receitas/STORE_RECEITAS_CLIENTES",
        this.form.empresa_id
      );
      this.$store.dispatch("receitas/listaReceitas");
    },

    receber(item) {
      this.dados = item;
      this.$bvModal.show("receberReceita");
    },

    editar(item) {
      this.dados = item;
      this.$bvModal.show("editarReceita");
    },
    excluir(id, index) {
      this.$bvModal
        .msgBoxConfirm("Deseja realmente excluir essa receita?", {
          title: "Excluir Receita",
          size: "sm",
          okVariant: "primary",
          okTitle: "Sim",
          cancelTitle: "Não",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((valor) => {
          if (valor) {
            this.$store.commit("receitas/DELETAR_RECEITA", index);
            this.$http.delete("adm/receitas/excluir/" + id);
            this.$store.dispatch("receitas/listaReceitas");
          }
        });
    },
  },
  filters: {
    formataMoeda(valor) {
      if (!valor) return "0,00";

      let vl = parseFloat(valor);
      return vl.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    formataData(data) {
      if (!data) return;

      let d = data.split("-");
      return `${d[2]}/${d[1]}/${d[0]}`;
    },

    toUpperCase(texto) {
      if (!texto) return;

      return texto[0].toUpperCase() + texto.substr(1);
    },
  },
};
</script>