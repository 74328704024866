<template>
  <b-modal
    id="editarReceita"
    no-close-on-backdrop
    no-close-on-esc
    centered
    hide-footer
    size="xl"
    title="Editar Receita"
  >
    <b-card>
      <b-alert :variant="getErrors.color" v-if="getErrors.hasMessage" show>
        <div
          class="alert-body"
          v-for="(error, index) in getErrors.messageArray"
          :key="index"
        >
          {{ error[0] }}
        </div>

        <div class="alert-body">{{ getErrors.message }}</div>
      </b-alert>
      <validation-observer ref="receitaCadastroValidation">
        <b-row>
          <!--  DATA LANÇAMENTO -->
          <b-col lg="4" md="6">
            <validation-provider
              #default="{ errors }"
              name="Data de Lançamento"
              rules="required"
            >
              <b-form-group label="Data de Lançamento" label-for="compra">
                <b-form-datepicker
                  id="compra"
                  v-model="form.data_lancamento"
                  :date-format-options="{
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                  }"
                  close-button
                  hide-header
                  label-close-button="Fechar"
                  label-reset-button="Apagar Data"
                  label-help=""
                  locale="pt-BR"
                  placeholder="Data"
                  reset-button
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!--  FORNECEDOR -->
          <b-col>
            <validation-provider
              #default="{ errors }"
              name="Cliente"
              rules="required"
            >
              <b-form-group label="Cliente" label-for="cliente">
                <v-select
                  style="background-color: white"
                  id="cliente"
                  v-model="form.empresa_id"
                  :reduce="(empresas) => empresas.id"
                  label="nome"
                  :options="empresas"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="3">
            <validation-provider
              #default="{ errors }"
              name="Forma Recebimento"
              rules="required"
            >
              <b-form-group
                label="Forma Recebimento"
                label-for="formaPagamento"
              >
                <b-form-select
                  v-model="form.forma_recebimento"
                  :options="formas"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-provider
              #default="{ errors }"
              name="Descrição"
              rules="required"
            >
              <b-form-group label="Descrição" label-for="descricao">
                <b-form-input id="descricao" v-model="form.descricao" />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="3">
            <validation-provider
              #default="{ errors }"
              name="Valor"
              rules="required|between:0,999999"
            >
              <b-form-group label="Valor" label-for="valor_parcela">
                <b-input-group prepend="R$">
                  <money
                    id="valor"
                    v-model="form.valor"
                    v-bind="money"
                    class="form-control"
                  />
                </b-input-group>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>
      <b-row class="mt-2">
        <b-col>
          <b-button
            :disabled="submit"
            block
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            @click="editar"
            variant="success"
          >
            <template>
              <b-spinner v-if="spinner" small />
              <span v-if="spinner">Aguarde...</span>
              <span v-else>Confirmar Edição</span>
            </template>
          </b-button>
        </b-col>
        <b-col>
          <b-button
            block
            variant="outline-secondary"
            @click="$bvModal.hide('editarReceita')"
          >
            Fechar
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-modal>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BModal,
  BFormGroup,
  BFormSelect,
  BFormDatepicker,
  BFormInput,
  BInputGroup,
  BSpinner,
  BAlert,
} from "bootstrap-vue";
import vSelect from "vue-select";
import money from "v-money";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { min, max, required } from "@validations";
import { mapState, mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formaRecebimento from "../formaRecebimento"
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BModal,
    BFormGroup,
    BFormSelect,
    BFormDatepicker,
    BFormInput,
    BInputGroup,
    BSpinner,
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BAlert,
    ToastificationContent,
  },

  props: ["form"],

  data() {
    return {
      errors: false,
      required,
      min,
      max,
      spinner: false,
      submit: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      getErrors: {
        hasMessage: false,
        color: "danger",
        message: "",
        messageArray: "",
      },
      ...formaRecebimento
    };
  },

  mounted() {},

  computed: {
    ...mapState({
      empresas: (state) => state.empresas.empresas,
    }),
  },

  methods: {
    editar() {
      this.$refs.receitaCadastroValidation.validate().then((success) => {
        if (success) {
          this.spinner = true;
          this.submit = true;
          this.errors = false;

          let indexCliente = this.empresas.findIndex(v => v.id == this.form.empresa_id)


          let payload = {
            ...this.form,
            cliente: this.empresas[indexCliente].nome
          }

          this.$http
            .put("adm/receitas/editar/"+this.form.id, this.form)
            .then((res) => {
              if (res.data) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Receita Editada com Sucesso!",
                    icon: "InfoIcon",
                    variant: "success",
                  },
                });

                this.$store.commit('receitas/EDITAR_RECEITA', payload)
                this.$store.dispatch("receitas/listaReceitas");
              }
              this.getErrors = false;
              this.$refs.receitaCadastroValidation.reset();
            })
            .catch((error) => {
              let erro = this.$responseMessages.getMessage(error, null);
              this.getErrors = erro;
            })
            .finally(() => {
              this.spinner = false;
              this.submit = false;
            });
        }
      });
    },
  },
};
</script>

<style>
</style>